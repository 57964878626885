// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about_us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gardens-js": () => import("./../../../src/pages/gardens.js" /* webpackChunkName: "component---src-pages-gardens-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photo-gallery-js": () => import("./../../../src/pages/photo_gallery.js" /* webpackChunkName: "component---src-pages-photo-gallery-js" */),
  "component---src-pages-wine-vineyards-js": () => import("./../../../src/pages/wine_vineyards.js" /* webpackChunkName: "component---src-pages-wine-vineyards-js" */)
}

